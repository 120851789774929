/**
 * Type: ページ
 * What: トップページ
 */
import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { scroller, animateScroll as scroll } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PostListComponent from '../components/PostList/PostList.top';
import PostListStickyComponent from '../components/PostList/PostList.sticky';
import SimpleSwiper from '../components/Slider';
import SimpleSwiper2 from '../components/Slider.fade';
import '../sass/components/_index.scss';
import Image from '../util/Image';
import { Btn, Button, ImgWrap, SvgText, Text, Title } from '../components/Btn';
import useMediaQuery from '../util/useMediaQuery';
import TimerFunc from '../util/Timer';

const mq = useMediaQuery;

const pageSlug = 'index'; // body-index classを付与

const floorSlider1 = [
  {
    filename: `top-vw.jpg`,
  },
  {
    filename: `top-vw-2.jpg`,
  },
];
const floorSlider2 = [
  {
    filename: `top-tf.jpg`,
  },
  {
    filename: `top-tf-2.jpg`,
  },
];
const floorSlider3 = [
  {
    filename: `top-obm.jpg`,
  },
  {
    filename: `top-obm-2.jpg`,
  },
];
const floorSlider4 = [
  {
    filename: `top-ymt-2.jpg`,
  },
  {
    filename: `top-yoshimoto-logo.jpg`,
  },
];
const floorSlider5 = [
  {
    filename: `hkt-group-photo.jpg`,
  },
  {
    filename: `top-hkt-2.jpg`,
  },
];
const floorSlider6 = [
  {
    filename: `top-mlb.jpg`,
  },
  {
    filename: `top-mlb-2.jpg`,
  },
];
const floorSlider7 = [
  {
    filename: `top-fh.jpg`,
  },
  {
    filename: `top-fh-2.jpg`,
  },
];
const floorSlider8 = [
  {
    filename: `top-sanrio-left.jpg`,
  },
  {
    filename: `img-sanrio-area-7.jpg`,
  },
];

export const Tspan = (props) => (
  <tspan x={props.x} y={props.y}>
    {props.children}
  </tspan>
);
export const Exciting = (props) => (
  <>
    <svg width="635" height="220" viewBox="0 0 635 220">
      <text
        className="medium"
        x="0"
        y="0"
        textAnchor="end"
        dominantBaseline="central"
        letterSpacing="0"
      >
        {props.children}
      </text>
    </svg>
  </>
);

// Data Props Template
const IndexPage = () => {
  const PC = mq('pc');
  const data = useStaticQuery(graphql`
    {
      allWordpressAcfPages(filter: { wordpress_id: { eq: 3 } }) {
        nodes {
          acf {
            bnr_top_01_external
            bnr_top_01_link
            bnr_top_01_visible
            bnr_top_02_external
            bnr_top_02_link
            bnr_top_02_visible
            bnr_top_03_external
            bnr_top_03_link
            bnr_top_03_visible
            bnr_top_04_external
            bnr_top_04_link
            bnr_top_04_visible
            bnr_top_05_external
            bnr_top_05_link
            bnr_top_05_visible
            bnr_top_06_external
            bnr_top_06_link
            bnr_top_06_visible
            bnr_top_07_external
            bnr_top_07_link
            bnr_top_07_visible
            bnr_top_08_external
            bnr_top_08_link
            bnr_top_08_visible
            bnr_top_09_external
            bnr_top_09_link
            bnr_top_09_visible
            bnr_top_10_external
            bnr_top_10_link
            bnr_top_10_visible
            bnr_top_01_image {
              source_url
            }
            bnr_top_02_image {
              source_url
            }
            bnr_top_03_image {
              source_url
            }
            bnr_top_04_image {
              source_url
            }
            bnr_top_05_image {
              source_url
            }
            bnr_top_06_image {
              source_url
            }
            bnr_top_07_image {
              source_url
            }
            bnr_top_08_image {
              source_url
            }
            bnr_top_09_image {
              source_url
            }
            bnr_top_10_image {
              source_url
            }
            bnr_top_01_order
            bnr_top_02_order
            bnr_top_03_order
            bnr_top_04_order
            bnr_top_05_order
            bnr_top_06_order
            bnr_top_07_order
            bnr_top_08_order
            bnr_top_09_order
            bnr_top_10_order
          }
        }
      }
      site {
        siteMetadata {
          ticketReady
          popUp
          eventHall
        }
      }
    }
  `);
  const post = data.allWordpressAcfPages.nodes;
  const url = data.site.siteMetadata;
  const slider = [
    {
      src: post[0].acf.bnr_top_01_image.source_url,
      link: post[0].acf.bnr_top_01_link,
      external: post[0].acf.bnr_top_01_external,
      visible: post[0].acf.bnr_top_01_visible,
      order: post[0].acf.bnr_top_01_order,
    },
    {
      src: post[0].acf.bnr_top_02_image.source_url,
      link: post[0].acf.bnr_top_02_link,
      external: post[0].acf.bnr_top_02_external,
      visible: post[0].acf.bnr_top_02_visible,
      order: post[0].acf.bnr_top_02_order,
    },
    {
      src: post[0].acf.bnr_top_03_image.source_url,
      link: post[0].acf.bnr_top_03_link,
      external: post[0].acf.bnr_top_03_external,
      visible: post[0].acf.bnr_top_03_visible,
      order: post[0].acf.bnr_top_03_order,
    },
    {
      src: post[0].acf.bnr_top_04_image.source_url,
      link: post[0].acf.bnr_top_04_link,
      external: post[0].acf.bnr_top_04_external,
      visible: post[0].acf.bnr_top_04_visible,
      order: post[0].acf.bnr_top_04_order,
    },
    {
      src: post[0].acf.bnr_top_05_image.source_url,
      link: post[0].acf.bnr_top_05_link,
      external: post[0].acf.bnr_top_05_external,
      visible: post[0].acf.bnr_top_05_visible,
      order: post[0].acf.bnr_top_05_order,
    },
    {
      src: post[0].acf.bnr_top_06_image.source_url,
      link: post[0].acf.bnr_top_06_link,
      external: post[0].acf.bnr_top_06_external,
      visible: post[0].acf.bnr_top_06_visible,
      order: post[0].acf.bnr_top_06_order,
    },
    {
      src: post[0].acf.bnr_top_07_image.source_url,
      link: post[0].acf.bnr_top_07_link,
      external: post[0].acf.bnr_top_07_external,
      visible: post[0].acf.bnr_top_07_visible,
      order: post[0].acf.bnr_top_07_order,
    },
    {
      src: post[0].acf.bnr_top_08_image.source_url,
      link: post[0].acf.bnr_top_08_link,
      external: post[0].acf.bnr_top_08_external,
      visible: post[0].acf.bnr_top_08_visible,
      order: post[0].acf.bnr_top_08_order,
    },
    {
      src: post[0].acf.bnr_top_09_image.source_url,
      link: post[0].acf.bnr_top_09_link,
      external: post[0].acf.bnr_top_09_external,
      visible: post[0].acf.bnr_top_09_visible,
      order: post[0].acf.bnr_top_09_order,
    },
    {
      src: post[0].acf.bnr_top_10_image.source_url,
      link: post[0].acf.bnr_top_10_link,
      external: post[0].acf.bnr_top_10_external,
      visible: post[0].acf.bnr_top_10_visible,
      order: post[0].acf.bnr_top_10_order,
    },
  ];

  const checkUrl = () => {
    // URLにパラメーターがある場合（他画面のnavからunkomuseumに画面遷移しスクロール）
    const para = window.location.search.substring(1);
    // パラメータが存在する場合
    if (para) {
      scrollToTarget(para);
    }
  };

  const scrollToTarget = (elem, offset) => {
    let $offset = 0;
    if (window.matchMedia('screen and (min-width: 769px)').matches) {
      $offset = offset || -1300;
    } else {
      $offset = offset || -110;
    }
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  const ankerLinkScroll = (e, elem, offset) => {
    e.preventDefault();
    let $offset = offset || -160;
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      $offset = offset || -110;
    }
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  const [fetchReady, setFetchReady] = useState(false);

  useEffect(() => {
    setFetchReady(true);
    checkUrl();
  }, [checkUrl]);

  const orderCheck = (a, b) => {
    const orderA = a.order < 1 ? 99 : a.order;
    const orderB = b.order < 1 ? 99 : b.order;

    return orderA - orderB;
  };

  const orderBySlider = slider.sort(orderCheck);

  return (
    <>
      <Layout pageSlug={pageSlug}>
        <SEO title="" />
        {/*
        <div className="translate-text">
          <p>このページには、機械翻訳されたテキストが含まれています。わかりにくい表現が見つかる場合がありますので、あらかじめご了承ください。</p>
        </div>
        */}
        <div className="kv top-photo">
          <div className="main-photo">
            <Image filename="img-main-top.jpg" />
          </div>
          <div className="kv-inner">
            <SimpleSwiper data={orderBySlider} isPc={PC} />
            <div className="btn-wrap flex-btns mb-0">
              <Button href="/ticket/how/" className="btn-buy">
                チケット情報
              </Button>
            </div>
            {/* <div className="btn-group mt-5">
              <Link
                to="/news/info/20230311252/"
                className="btn-def caution-ticket-btn narrow"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <span>新型コロナウイルス感染予防の対策について</span>
              </Link>
            </div> */}
            <PostListStickyComponent />
            {/* <div className="top-bnr">
              <a href="/zekkei-brothers/" >
                <Image filename="bnr-zekkei.jpg" />
              </a>
            </div> */}
            <div className="top-bnr">
              <a
                href="https://www.softbankhawks.co.jp/stadium/parking/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image filename="bnr-parking.jpg" />
              </a>
            </div>
          </div>
        </div>
        <section className="section sc-topics">
          <div className="container">
            <h2 className="headline">
              <span>Topics</span>
            </h2>
            <PostListComponent />
            <div
              className="btn-wrap"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Button href="/news/">more</Button>
            </div>
          </div>
        </section>
        <section className="sc-top-attraction">
          <div className="section">
            {url.eventHall === '1' && (
              <div
                className="sc-top-attraction-simple event-hall"
                id="event-hall"
              >
                <h3 className="sc-top-attraction-ttl">
                  <span>イベント</span>
                </h3>

                <div className="container">
                  {/* 【推しの子】スーパー・イマーシブライブ */}
                  <div
                    className="col"
                    data-sal="slide-up"
                    data-sal-easing="ease-out-expo"
                  >
                    <Link
                      to="https://www.mangadive.jp/oshinoko-sil/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="col-inner">
                        <div
                          className="col-content"
                          data-sal="slide-up"
                          data-sal-easing="ease-out-expo"
                        >
                          <h3 className="ttl">
                            <span className="ttl-text">
                              マンガダイブ『【推しの子】』スーパー・イマーシブライブ
                            </span>
                          </h3>
                          <div className="caution-border mt-5 mb-5 ">
                            <p className="fwb text is-3 is-2-sp mt0">
                              次回開催予告：9/11（水）～
                            </p>
                          </div>
                        </div>
                        <div className="col-image">
                          <div className="image-wrap">
                            <figure className="image">
                              <Image filename="top-oshinoko.jpg" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div
                  className="event-anker-link-wrap"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <a
                    href="#play"
                    className="play"
                    onClick={(e) => ankerLinkScroll(e, 'play')}
                    onKeyDown={(e) => ankerLinkScroll(e, 'rf')}
                  >
                    <span>遊ぶ</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="14"
                      viewBox="0 0 18.438 14.47"
                      className="icon-anker"
                    >
                      <path
                        id="anker-arrow.svg"
                        className="cls-1"
                        d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                        transform="translate(-384.281 -2431.59)"
                      />
                    </svg>
                  </a>
                  <a
                    href="#watch"
                    className="watch"
                    onClick={(e) => ankerLinkScroll(e, 'watch')}
                    onKeyDown={(e) => ankerLinkScroll(e, 'rf')}
                  >
                    <span>観る</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="14"
                      viewBox="0 0 18.438 14.47"
                      className="icon-anker"
                    >
                      <path
                        id="anker-arrow.svg"
                        className="cls-1"
                        d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                        transform="translate(-384.281 -2431.59)"
                      />
                    </svg>
                  </a>
                  <a
                    href="#eat"
                    className="eat"
                    onClick={(e) => ankerLinkScroll(e, 'eat')}
                    onKeyDown={(e) => ankerLinkScroll(e, 'rf')}
                  >
                    <span>食べる</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="14"
                      viewBox="0 0 18.438 14.47"
                      className="icon-anker"
                    >
                      <path
                        id="anker-arrow.svg"
                        className="cls-1"
                        d="M402.711,2438.44v-6.12a0.713,0.713,0,0,0-1.152-.57l-7.628,6.15a0.7,0.7,0,0,1-.883,0l-7.606-6.13a0.715,0.715,0,0,0-1.154.57v6.13a0.739,0.739,0,0,0,.271.57l8.489,6.83a0.7,0.7,0,0,0,.883,0l8.51-6.86a0.709,0.709,0,0,0,.27-0.57"
                        transform="translate(-384.281 -2431.59)"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            )}
            <div className="sc-top-attraction-simple event-hall" id="play">
              <h3 className="sc-top-attraction-ttl">
                <span>遊ぶ</span>
              </h3>
              <div className="container">
                {/* 絶景3 */}
                <div
                  className="col"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Link to="/zekkei-brothers/">
                    <div className="col-inner">
                      <div className="col-content">
                        <h3 className="ttl">
                          <span className="floorNoSimple c-12 zekkei">RF</span>
                          <Image
                            src="logo-zekkei-brothers-w.png"
                            alt="絶景3兄弟 SMBC日興証券 屋上アトラクション"
                            className="pc-mb20 sp-mb20"
                          />
                          <div className="ttl-flex">
                            <Image
                              src="img-suberizo.png"
                              alt="すべZO SMBC日興証券"
                              className="col-3"
                            />
                            <Image
                              src="img-turizo.png"
                              alt="つりZO SMBC日興証券"
                              className="col-3"
                            />
                            <Image
                              src="img-noborizo.png"
                              alt="のぼZO SMBC日興証券"
                              className="col-3"
                            />
                          </div>
                        </h3>
                        <p className="text">
                          眺めも気分も爽快！
                          <br />
                          日本初登場を含む3種類の絶景アトラクション
                        </p>
                      </div>
                      <div className="col-image">
                        <div className="image-wrap">
                          <figure className="image pc">
                            <Image
                              filename="top-sube.jpg"
                              alt="絶景3兄弟 SMBC日興証券 屋上アトラクション"
                            />
                          </figure>
                          <figure className="image pc">
                            <Image
                              filename="top-tsuri.jpg"
                              alt="絶景3兄弟 SMBC日興証券 屋上アトラクション"
                            />
                          </figure>
                          <figure className="image pc">
                            <Image
                              filename="top-nobo.jpg"
                              alt="絶景3兄弟 SMBC日興証券 屋上アトラクション"
                            />
                          </figure>
                          <figure className="image sp">
                            <Image
                              filename="top-zekkei-sp.jpg"
                              alt="絶景3兄弟 SMBC日興証券 屋上アトラクション"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* Sanrio characters Dream!ng Park */}
                <div
                  className="col"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Link to="/dreamingpark/">
                    <div className="col-inner">
                      <div className="col-content">
                        <h3 className="ttl">
                          <span className="floorNoSimple c-12">7F</span>
                          <Image
                            src="logo-sanrio.png"
                            alt="Sanrio characters Dream!ng Park"
                          />
                        </h3>
                        <p className="text">
                          サンリオのキャラクターと友だちになって遊びつくす、
                          <br className="sp" />
                          共遊型コミュニケーションパーク
                        </p>
                      </div>
                      <div className="col-image">
                        <div className="image-wrap">
                          {floorSlider8.map((categories) => (
                            <figure
                              className="image pc"
                              key={categories.filename}
                            >
                              <Image
                                filename={categories.filename}
                                alt="Sanrio characters Dream!ng Park"
                              />
                            </figure>
                          ))}
                          <figure className="image sp">
                            <SimpleSwiper2 data={floorSlider8} />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <p className="credit">&copy;2023 SANRIO CO., LTD.</p>
                </div>
                {/* V-World AREA */}
                <div
                  className="col"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Link to="/v-world/">
                    <div className="col-inner">
                      <div className="col-content">
                        <h3 className="ttl">
                          <span className="floorNoSimple c-12">6F</span>
                          <Image
                            src="logo-vr.png"
                            alt="V-World AREA クレディ・アグリコル"
                          />
                        </h3>
                        <p className="text">
                          和の雰囲気に彩られた空間で、
                          <br />
                          様々なバーチャルコンテンツが体験できる最新施設！
                        </p>
                      </div>
                      <div className="col-image">
                        <div className="image-wrap">
                          {floorSlider1.map((categories) => (
                            <figure
                              className="image pc"
                              key={categories.filename}
                            >
                              <Image
                                filename={categories.filename}
                                alt="V-World AREA クレディ・アグリコル"
                              />
                            </figure>
                          ))}
                          <figure className="image sp">
                            <SimpleSwiper2 data={floorSlider1} />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* チームラボフォレスト – SBI証券） */}
                <div
                  className="col"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Link to="/teamlabforest/">
                    <div className="col-inner">
                      <div className="col-content">
                        <h3 className="ttl">
                          <span className="floorNoSimple c-12">5F</span>
                          <Image
                            src="logo-teamlabforest-w.png"
                            alt="チームラボフォレスト 福岡 – SBI証券"
                          />
                        </h3>
                        <p className="text">
                          アート集団チームラボによる「捕まえて集める森」と
                          <br />
                          「運動の森」からなる新しいミュージアム。
                        </p>
                      </div>
                      <div className="col-image">
                        <div className="image-wrap">
                          {floorSlider2.map((categories) => (
                            <figure
                              className="image pc"
                              key={categories.filename}
                            >
                              <Image
                                filename={categories.filename}
                                alt="チームラボフォレスト 福岡 – SBI証券"
                              />
                            </figure>
                          ))}
                          <figure className="image sp">
                            <SimpleSwiper2 data={floorSlider2} />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <p className="credit">
                    「チームラボフォレスト 福岡 - SBI証券」
                    みずほPayPayドーム福岡隣 &copy; チームラボ
                  </p>
                </div>
                {/* 王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ */}
                <div
                  className="col obm"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Link to="/obm/">
                    <div className="col-inner">
                      <div className="col-content">
                        <h3 className="ttl">
                          <span className="floorNoSimple c-12">4F</span>
                          <Image
                            src="logo-obm.png"
                            alt="王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ"
                          />
                        </h3>
                        <p className="text">
                          王貞治の魅力を伝え、体を動かすことを楽しむ、
                          <br />
                          未来志向のスポーツミュージアム
                        </p>
                      </div>
                      <div className="col-image">
                        <div className="image-wrap">
                          {floorSlider3.map((categories) => (
                            <figure
                              className="image pc"
                              key={categories.filename}
                            >
                              <Image
                                filename={categories.filename}
                                alt="王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ"
                              />
                            </figure>
                          ))}
                          <figure className="image sp">
                            <SimpleSwiper2 data={floorSlider3} />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div className="sub-image-wrap">
                    <div className="sub-image">
                      <Link to="/obm/museum/">
                        <Image
                          src="top-obm-sub.jpg"
                          alt="王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ"
                        />
                      </Link>
                    </div>
                    <div className="sub-image">
                      <Link to="/obm/89park/">
                        <Image
                          src="top-obm-sub-2.jpg"
                          alt="王貞治ベースボールミュージアム Supported by DREAM ORDER・89パーク・スタジオ"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sc-top-attraction-simple" id="watch">
              <h3 className="sc-top-attraction-ttl">
                <span>観る</span>
              </h3>
              <div className="container">
                {/* よしもと福岡 大和証券劇場 */}
                <div
                  className="col"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Link to="/yoshimoto-fukuoka/">
                    <div className="col-inner">
                      <div className="col-content">
                        <h3 className="ttl">
                          <span className="floorNoSimple c-12">7F</span>
                          <Image
                            src="logo-yoshimoto-w.png"
                            alt="よしもと福岡 大和証券劇場"
                          />
                        </h3>
                        <p className="text">
                          吉本興業の新たな常設劇場が福岡に誕生
                        </p>
                      </div>
                      <div className="col-image">
                        <div className="image-wrap">
                          {floorSlider4.map((categories) => (
                            <figure
                              className="image pc"
                              key={categories.filename}
                            >
                              <Image
                                filename={categories.filename}
                                alt="よしもと福岡 大和証券劇場"
                              />
                            </figure>
                          ))}
                          <figure className="image sp">
                            <SimpleSwiper2 data={floorSlider4} />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* HKT48 劇場 */}
                <div
                  className="col"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Link to="/hkt/">
                    <div className="col-inner">
                      <div className="col-content">
                        <h3 className="ttl">
                          <span className="floorNoSimple c-12">1F</span>
                          <Image
                            src="logo-hkt-w.png"
                            alt="HKT48 劇場"
                            className="logo-small"
                          />
                        </h3>
                        <p className="text">
                          福岡を拠点に幅広く活動するHKT48の専用劇場
                          <br />
                        </p>
                      </div>
                      <div className="col-image">
                        <div className="image-wrap">
                          {floorSlider5.map((categories) => (
                            <figure
                              className="image pc"
                              key={categories.filename}
                            >
                              <Image
                                filename={categories.filename}
                                alt="HKT48 劇場"
                              />
                            </figure>
                          ))}
                          <figure className="image sp">
                            <SimpleSwiper2 data={floorSlider5} />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="sc-top-attraction-simple" id="eat">
              <h3 className="sc-top-attraction-ttl">
                <span>食べる</span>
              </h3>
              <div className="container">
                {/* The FOODHALL（フードホール） */}
                <div
                  className="col"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Link to="/the-foodhall/">
                    <div className="col-inner">
                      <div className="col-content">
                        <h3 className="ttl">
                          <span className="floorNoSimple c-12">3F</span>
                          <span className="ttl-text">The FOODHALL</span>
                        </h3>
                        <p className="text">
                          日本初出店を含む話題店が集うフードホール
                        </p>
                      </div>
                      <div className="col-image">
                        <div className="image-wrap">
                          {floorSlider7.map((categories) => (
                            <figure
                              className="image pc"
                              key={categories.filename}
                            >
                              <Image
                                filename={categories.filename}
                                alt="The FOODHALL（フードホール）"
                              />
                            </figure>
                          ))}
                          <figure className="image sp">
                            <SimpleSwiper2 data={floorSlider7} />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="exciting-wrap">
            <div className="bgImage2">
              <div className="bgImage-background">
                <Image src="bg-exciting-coverImage.jpg" alt="" />
              </div>
              <div className="bgImage-cover">
                <Image src="bg-exciting-coverImage-bk.png" alt="" />
              </div>
            </div>
            <div
              className="svgtext"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Exciting>
                {PC ? (
                  <Tspan x="565" y="26">
                    Exciting
                  </Tspan>
                ) : (
                  <Tspan x="275" y="26">
                    Exciting
                  </Tspan>
                )}
                <Tspan x="600" y="110">
                  + Entertainment
                </Tspan>
                <Tspan x="635" y="189">
                  + Zone
                </Tspan>
              </Exciting>
            </div>
          </div>
        </section>

        <section className="sc-access">
          <div className="container">
            <div className="border-box">
              <h2 className="headline">
                <span>Access</span>
              </h2>
              <div className="sc-access-row">
                <div className="sc-access-image">
                  <Image src="img-access-map.png" alt="Access MAP" />
                </div>
                <div className="sc-access-address">
                  <p>福岡市中央区地⾏浜2-2-6</p>
                  <ul>
                    <li>
                      ・福岡市地下鉄唐人町駅下車、3番出口より徒歩約15分。
                      <br />
                      唐人町-天神間は、3～8分間隔で運行しております。
                    </li>
                  </ul>
                  <ul>
                    <li>・西鉄バス「PayPayドーム」5ゲート 徒歩9分</li>
                    <li>・西鉄バス「九州医療センター」5ゲート 徒歩3分</li>
                    <li>
                      ・西鉄バス「ヒルトン福岡シーホーク前」5ゲート 徒歩6分
                    </li>
                  </ul>
                  <ul>
                    <li>・福岡都市高速道路「西公園ランプ」より約5分</li>
                    <li>・福岡都市高速道路「百道ランプ」より約5分</li>
                  </ul>
                </div>
              </div>
              <div
                className="btn-wrap"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <Button href="/access/">more</Button>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default IndexPage;
