/**
 * Type: 投稿コンポーネント
 * What: TOPページ用最新10件の投稿リスト(TOP固定を除く)
 */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Card from './Card';

const PostListTopComponent = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPost(limit: 6) {
        nodes {
          title
          path
          excerpt
          date(formatString: "YYYY.MM.DD")
          sticky
          id
          featured_media {
            id
            source_url
          }
          categories {
            id
            slug
            name
          }
        }
      }
    }
  `);

  const posts = data.allWordpressPost.nodes;
  // const ttl = props.children;
  return (
    <>
      { posts.length ? (
        <div className="columns is-multiline top-columns">
          {posts.map((post) => (
            <div className="column is-3" key={post.id}>
              <Card {...post} />
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default PostListTopComponent;
