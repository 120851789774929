/**
 * Type: 投稿コンポーネント
 * What: TOPに固定の投稿リスト
 */
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import HtmlEscape from '../../util/htmlEscape';

const PostListStickyComponent = (props) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPost(filter: { sticky: { in: true } }) {
        nodes {
          title
          path
          excerpt
          date(formatString: "YYYY.MM.DD")
          sticky
          id
        }
      }
    }
  `);
  const post = data.allWordpressPost.nodes;
  // eslint-disable-next-line react/destructuring-assignment
  // const ttl = props.children;
  return (
    <>
      {post.length ? (
        <div className="message-wrap">
          <ul className="">
            {post.map((posts) => (
              <li className="message-list" key={posts.id}>
                <Link to={posts.path} className="column is-full">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: HtmlEscape(posts.title),
                    }}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default PostListStickyComponent;
