import React from 'react';
import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';
import Image from '../util/Image';

import 'swiper/css/swiper.css';
import '../sass/components/_slider.scss';

class SimpleSwiper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: '',
      didMount: '',
      loading: true,
      slides: props.data,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        didMount: true,
        params: {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          centeredSlides: true,
          slidesPerView: this.props.isPc ? 3 : 1,
          loop: true,
          effect: this.props.isPc ? 'slide' : 'fade',
          speed: 600,
          rebuildOnUpdate: true,
          allowTouchMove: true,
          getSwiper: (swiper) => {
            swiper.on('slideChange', () => {
              // console.log(`slide changed : ${swiper.activeIndex}`);
            });
          },
        },
      });
    }, 0);

    setTimeout(() => {
      this.setState({
        loading: '',
      });
    }, 400);

    setTimeout(() => {
      this.setState({
        visible: true,
      });
    }, 600);
  }

  /*
  updateSwiper = ($obj) => {
    this.setState({
      swiperObj: $obj,
    });
  }
*/

  /*
  goNext = () => {
    if (this.state.swiperObj !== null) {
      this.state.swiperObj.slideNext();
    }
  }

  goPrev = () => {
    if (this.state.swiperObj !== null) {
      this.state.swiperObj.slidePrev();
    }
  }

  goSlideTo = (index) => {
    if (this.state.swiperObj !== null) {
      this.state.swiperObj.slideTo(index, 800);
    }
  }
*/

  render() {
    const {
      slides, visible, didMount, loading, params,
    } = this.state;
    const visibleSider = slides.filter((val) => val.visible);
    return (
      <div className={`slider-wrap ${didMount && 'is-didMount'} ${visible && 'is-visible'}`}>
        { !visible && (
        <div className={`loader-wrapper ${loading && 'loading'}`}>
          <div className="loader is-loading" />
        </div>
        )}
        <Swiper {...params}>
          {
            visibleSider.map((obj) => (
              <div className="slide" key={obj.filename || obj.src}>
                <div>
                  {obj.link.length > 0 && obj.external && (
                    <a href={obj.link} target="_blank" rel="noopener noreferrer">
                      {obj.src ? (
                        <img src={obj.src} alt="" className="" />
                      ) : (
                        <Image src={obj.filename} alt="" className="" />
                      )}
                    </a>
                  )}

                  {obj.link.length > 0 && !obj.external && (
                    <Link to={obj.link}>
                      {obj.src ? (
                        <img src={obj.src} alt="" className="" />
                      ) : (
                        <Image src={obj.filename} alt="" className="" />
                      )}
                    </Link>
                  )}

                  {obj.link.length < 1 && (
                    <div>
                      {obj.src ? (
                        <img src={obj.src} alt="" className="" />
                      ) : (
                        <Image src={obj.filename} alt="" className="" />
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))
          }
        </Swiper>
      </div>
    );
  }
}

export default SimpleSwiper;
